<template>
  <!-- element:dialog START -->
  <el-dialog
    :visible.sync="dialogVisible"
    :before-close="dialogClose"
    :fullscreen="true"
    :append-to-body="true"
    width="80%"
  >
    <span slot="title" v-if="title" v-html="title"></span>
    <h2 v-if="headline" v-html="headline"></h2>
    <div class="description" v-if="description" v-html="description"></div>
  </el-dialog>
  <!-- element:dialog END -->
</template>

<script>
export default {
  name: 'dialog-default',
  props: {
    title: {
      type: String,
    },

    headline: {
      type: String,
    },

    description: {
      type: String,
    },

    dialogVisible: {
      type: Boolean,
      default: false,
    },

    dialogClose: {
      type: Function,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      dialogVisibleChild: null,
    };
  },
  mounted() {
    this.dialogClose();
  },
};
</script>
